import { useCallback, useMemo } from "react";
import { getNumericPattern, getPlatePattern } from "../../../../shared/utils";
import {
  HandlersByServiceType,
  IRegisterByServiceTypeResult,
  ServiceType,
  UseRegisterByServiceType,
} from "../PaymentServices.interfaces";

const useRegisterByServiceType: UseRegisterByServiceType = ({
  serviceType,
  register,
  unregister,
}) => {
  const getRegisterIdNumber = useCallback(() => {
    unregister("vehiclePlate");
    unregister("orderNumber");

    return register("idNumber", {
      validate: (value: string) => {
        if (value === undefined || value === "") {
          return "Este campo es requerido";
        }

        const isNumeric = getNumericPattern().test(value);

        if (!isNumeric) {
          return "El campo debe contener sólo números";
        }

        if (value.length !== 10) {
          return "El número de cédula es inválido";
        }

        return true;
      },
      maxLength: 10,
    });
  }, [register, unregister]);

  const getRegisterVehiclePlate = useCallback(() => {
    unregister("idNumber");
    unregister("orderNumber");

    return register("vehiclePlate", {
      validate: (value) => {
        if (value === undefined || value === "") {
          return "Este campo es requerido";
        }

        const lettersOrNumbers =
          /^[A-Za-z\s]*$/.test(value) || /^[0-9]*$/.test(value);

        if (lettersOrNumbers) {
          return "El campo debe contener letras y números";
        }

        const isValid = getPlatePattern().test(value);

        if (!isValid) {
          return "El número de placa es inválido";
        }

        return true;
      },
      maxLength: 7,
    });
  }, [register, unregister]);

  const getRegisterOrderNumber = useCallback(() => {
    unregister("idNumber");
    unregister("vehiclePlate");

    return register("orderNumber", {
      validate: (value: string) => {
        if (value === undefined || value === "") {
          return "Este campo es requerido";
        }

        const isNumeric = getNumericPattern().test(value);

        if (!isNumeric) {
          return "El campo debe contener sólo números";
        }

        if (value.length <= 2 || value.length > 10) {
          return "El número de orden de pago es inválido";
        }

        return true;
      },
      maxLength: 10,
    });
  }, [register, unregister]);

  const getRegisterInput = useCallback(
    (st: ServiceType) => {
      const handlersByServiceType: HandlersByServiceType = {
        CIT_ID: getRegisterIdNumber,
        CIT_PLATE: getRegisterVehiclePlate,
        SOL: getRegisterOrderNumber,
        CDP: getRegisterIdNumber,
      };

      const registerFunctionByServiceType = handlersByServiceType[st];

      const registerInput = registerFunctionByServiceType();

      return registerInput;
    },
    [getRegisterIdNumber, getRegisterVehiclePlate, getRegisterOrderNumber]
  );

  return useMemo<IRegisterByServiceTypeResult>(() => {
    const registerInput = getRegisterInput(serviceType);

    return {
      registerInput,
    };
  }, [serviceType, getRegisterInput]);
};

export default useRegisterByServiceType;
