import { createNamedStyles } from "../../../shared/utils";

export const selectButtonStyles = createNamedStyles({
  button: {
    height: "auto",
    width: "auto",
    px: 2,
    py: "1px",
    color: "text.dark",
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "neutral.grey5",
    display: "flex",
    justifyContent: "start",
    bgcolor: "background.paper",
    boxShadow: "none",

    "&:focus": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "neutral.grey5",
      boxShadow: "none",
    },

    "&:hover": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "neutral.grey5",
      boxShadow:
        "0px 4px 6px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    },

    "&:active": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary.main",
      boxShadow: "none",
    },

    "&:disabled": {
      color: "text.dark",
      bgcolor: "primary.light3",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "neutral.grey1",
      opacity: 0.5,
      boxShadow: "none",
    },
  },

  selected: {
    color: "text.dark",
    bgcolor: "background.paper",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "primary.main",
    boxShadow: "none",

    "&:focus": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary.main",
      boxShadow: "none",
    },

    "&:hover": {
      color: "text.dark",
      bgcolor: "background.paper",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary.main",
      boxShadow:
        "0px 4px 6px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    },
  },
});
