import { Box, Button, TextField, Typography } from "@mui/material";
import { FC } from "react";
import SelectButton from "../../../common/SelectButton/SelectButton";
import ServiceFormLayout from "../Layouts/InputLayout";
import ServiceLayout from "../Layouts/ServiceLayout";
import { ServicesProps } from "../PaymentServices.interfaces";
import { citationsServiceStyles as styles } from "./CitationsService.styles";
import useCitationsServiceState from "./useCitationsServiceState/useCitationsServiceState";
import { ServiceTypeEnum } from "../../../../shared/enums";

const CitationsService: FC<ServicesProps> = ({
  onValidSubmit,
  isDesktop = true,
}) => {
  const {
    errors,
    handleFormSubmit,
    handleServiceButtonClick,
    isValid,
    registerInput,
    serviceType,
    helperText,
    label,
    showInput,
  } = useCitationsServiceState({ onValidSubmit });

  return (
    <ServiceLayout>
      <Box sx={styles.titleContainer}>
        <Typography
          color="text.lightGrey"
          variant="subtitle1"
          sx={styles.sectionTitle}
        >
          Policía Nacional.
        </Typography>
        <Typography
          color="text.dark"
          variant="subtitle2"
          sx={styles.sectionText}
        >
          Tipo de documento
        </Typography>
      </Box>

      <Box sx={styles.buttonWrapper}>
        <SelectButton
          text="Cédula"
          onClick={() => handleServiceButtonClick(ServiceTypeEnum.CIT_ID)}
          selected={
            (isDesktop || showInput) && serviceType === ServiceTypeEnum.CIT_ID
          }
        />
        <SelectButton
          text="Placa"
          onClick={() => handleServiceButtonClick(ServiceTypeEnum.CIT_PLATE)}
          selected={
            (isDesktop || showInput) &&
            serviceType === ServiceTypeEnum.CIT_PLATE
          }
        />
      </Box>

      <ServiceFormLayout sx={styles.inputWrapper} onSubmit={handleFormSubmit}>
        {(isDesktop || showInput) && (
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            inputProps={{
              maxLength: serviceType === ServiceTypeEnum.CIT_PLATE ? 7 : 10,
            }}
            helperText={
              errors.idNumber?.message ||
              errors.vehiclePlate?.message ||
              helperText
            }
            error={!!(errors.vehiclePlate || errors.idNumber)}
            {...registerInput}
          />
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid}
        >
          Consultar
        </Button>
      </ServiceFormLayout>
    </ServiceLayout>
  );
};

export default CitationsService;
