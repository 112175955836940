import { Box, BoxProps } from "@mui/material";
import { FC } from "react";
import { layoutStyles as styles } from "./Layout.styles";

const ServiceLayout: FC<BoxProps> = ({ sx, ...rest }) => {
  const containerStyle = { ...styles.container, ...sx };

  return <Box sx={containerStyle} {...rest} />;
};

export default ServiceLayout;
