import { useCallback, useState } from "react";
import useServicesDynamicForm from "../../hooks/useServicesDynamicForm";
import { ServiceType } from "../../PaymentServices.interfaces";
import { UseCitationsServiceState } from "./interfaces";
import useTextByServiceType from "./useTextByServiceType";
import { ServiceTypeEnum } from "../../../../../shared/enums";

const useCitationsServiceState: UseCitationsServiceState = ({
  onValidSubmit,
}) => {
  const {
    changeServiceType,
    errors,
    handleFormSubmit,
    isValid,
    registerInput,
    serviceType,
  } = useServicesDynamicForm({
    initialServiceType: ServiceTypeEnum.CIT_ID,
    onValidSubmit,
  });

  const { helperText, label } = useTextByServiceType({ serviceType });
  const [showInput, setShowInput] = useState(false);

  const handleServiceButtonClick = useCallback(
    (st: ServiceType): void => {
      changeServiceType(st);
      setShowInput(true);
    },
    [changeServiceType, setShowInput]
  );

  return {
    errors,
    handleFormSubmit,
    isValid,
    registerInput,
    serviceType,
    helperText,
    label,
    handleServiceButtonClick,
    showInput,
  };
};

export default useCitationsServiceState;
