import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  ServicesForm,
  ServiceType,
  UseServicesDynamicForm,
} from "../PaymentServices.interfaces";
import useRegisterByServiceType from "./useRegisterByServiceType";

const useServicesDynamicForm: UseServicesDynamicForm = ({
  onValidSubmit,
  initialServiceType,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    formState,
    watch,
    reset,
    setValue,
  } = useForm<ServicesForm>({
    defaultValues: { serviceType: initialServiceType },
    mode: "all",
    reValidateMode: "onChange",
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const serviceType = watch("serviceType");

  const { registerInput } = useRegisterByServiceType({
    register,
    serviceType,
    unregister,
  });

  const handleFormSubmit = handleSubmit(onValidSubmit);

  const changeServiceType = (st: ServiceType) => {
    if (serviceType === st) return;

    reset();
    setValue("serviceType", st, { shouldValidate: true });
  };

  const { errors, isValid } = formState;

  return {
    serviceType,
    errors,
    isValid,
    registerInput,
    changeServiceType,
    handleFormSubmit,
    open,
    handleOpen,
  };
};

export default useServicesDynamicForm;
