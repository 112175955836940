import { Box } from "@mui/material";
import { FC } from "react";
import { ServiceFormLayoutProps } from "./Layout.interfaces";
import { layoutStyles as styles } from "./Layout.styles";

const ServiceFormLayout: FC<ServiceFormLayoutProps> = ({
  sx,
  children,
  onSubmit,
  ...rest
}) => {
  const containerStyle = { ...styles.input, ...sx };

  return (
    <Box sx={containerStyle} {...rest}>
      <form onSubmit={onSubmit}>
        <Box sx={styles.innerForm}>{children}</Box>
      </form>
    </Box>
  );
};

export default ServiceFormLayout;
