import { useMemo } from "react";
import { UseTextByServiceType } from "./interfaces";
import { ServiceTypeEnum } from "../../../../../shared/enums";

const useTextByServiceType: UseTextByServiceType = ({ serviceType }) =>
  useMemo(() => {
    if (serviceType === ServiceTypeEnum.CIT_ID) {
      return {
        helperText: "Ejem: 0921234567",
        label: "Número de cédula",
      };
    }

    if (serviceType === ServiceTypeEnum.CIT_PLATE) {
      return {
        helperText: "Ejem: ABC1234",
        label: "Número de placa",
      };
    }

    return {
      helperText: "I have made mistakes",
      label: "I have made mistakes",
    };
  }, [serviceType]);

export default useTextByServiceType;
