import { createNamedStyles } from "../../../../shared/utils";

export const layoutStyles = createNamedStyles({
  container: {
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: { xs: "transparent", md: "primary.light3" },
    bgcolor: {
      xs: "transparent",
      md: "background.paper",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    py: { xs: 0, md: 4 },
  },
  input: {
    width: "100%",
    px: { xs: 0, md: 15 },
  },
  innerForm: {
    display: "grid",
    gridTemplateColumns: { xs: "1fr", md: "1fr auto" },
    gap: "16px",
  },
});
