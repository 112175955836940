import { createNamedStyles } from "../../../../shared/utils";

export const citationsServiceStyles = createNamedStyles({
  buttonWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "16px",
    mt: 1,
    px: { xs: 0, md: 15 },
  },
  inputWrapper: {
    mt: 3,
  },
  sectionTitle: {
    fontSize: { xs: "16px", md: "24px" },
    letterSpacing: { md: "0.0015em" },
    fontWeight: { xs: 400, md: 500 },
  },
  sectionText: {
    fontSize: { xs: "16px", md: "13px" },
    fontWeight: { xs: 400, md: 500 },
  },
  titleContainer: {
    textAlign: { xs: "left", md: "center" },
    width: "100%",
  },
});
