import { Button, FormControlLabel, Radio } from "@mui/material";
import { FC } from "react";
import { SelectButtonProps } from "./SelectButton.interfaces";
import { selectButtonStyles as styles } from "./SelectButton.styles";

const SelectButton: FC<SelectButtonProps> = ({
  selected = false,
  text = "",
  disabled,
  sx,
  ...rest
}) => {
  const selectedStyle = selected ? styles.selected : undefined;

  const buttonStyle = { ...styles.button, ...selectedStyle, ...sx };

  return (
    <Button sx={buttonStyle} {...rest} disabled={disabled}>
      <FormControlLabel
        value={selected ? "On" : "Off"}
        control={
          <Radio color="secondary" checked={selected} disabled={disabled} />
        }
        label={text}
      />
    </Button>
  );
};

export default SelectButton;
